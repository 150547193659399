import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";

import { AppStore } from "./store";
import { EmitAlertAction } from "./shared";
import { UserService } from "./user";


@Injectable()
export class AuthGuard {
    constructor(
        private store: Store<AppStore>,
        private router: Router,
        private oauthService: OAuthService,
        private userService: UserService
    ) {}

    canActivate(): Observable<boolean> {
        let unauthorized = () => {
            this.store.dispatch(EmitAlertAction({
                message: "You do not have access to this page.",
                alert_type: "info"
            }));

            this.router.navigateByUrl("");

            return of(false);
        };

        const token = this.oauthService.getIdToken();

        if (token) {
            return this.userService.checkUserAccess().pipe(
                switchMap((access: any) => {
                    if (access["full"]) {
                        return of(true);

                    } else {
                        return unauthorized();
                    }
                }),
                catchError((err: any) => {
                    return unauthorized();
                })
            );

        } else {
            return unauthorized();
        }
    }
}