import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";
import Web3 from "web3";

import { BaseService } from "../shared/services";
import { environment } from "../../environments/environment";


@Injectable()
export class UserService extends BaseService {
    private web3: any

    constructor(
        http: HttpClient,
        oauthService: OAuthService
    ) {
        super(http, oauthService);

        this.web3 = new Web3(window.ethereum);
    }

    public async fetchCurrentAccount() {
        return await window.ethereum.request({
            method: 'eth_requestAccounts'
        }).then((accounts: string[]) => {
            // console.log("All accounts:", accounts);
            return accounts[0];

        }).catch((err: any) => {
            console.log("Account Fetch err:", err);
        });
    }

    getUser(): Observable<any> {
        return new Observable(sub => {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then((account: string[]) => {
                let userAccount = account[0];

                //get the balance of the account
                this.web3.eth.getBalance(userAccount).then(( wei: number ) => {
                    return sub.next({
                        username: userAccount,
                        balance: this.web3.utils.fromWei(wei, "ether")
                    });
                });
            });
        });
    }

    checkConnection(): Observable<any> {
        return new Observable(sub => {
            window.ethereum.request({ method: 'eth_accounts' }).then((account: string[]) => {
                let userAccount = account[0];
                
                if (userAccount) {
                    //get the balance of the account
                    this.web3.eth.getBalance(userAccount).then((wei: number) => {
                        return sub.next({
                            username: userAccount,
                            balance: this.web3.utils.fromWei(wei, "ether")
                        });
                    });

                } else {
                    return sub.next({
                        username: "",
                        balance: 0
                    });
                }
            });
        })
    }

    updateCurrentChain(chainId: string): Observable<any> {
        return this.makeRequest({
            url: `api/updateCurrentChain?chainId=${chainId}`,
            method: BaseService.GET
        });
    }

    revokeRefreshToken() {
        const refreshToken = this.oauthService.getRefreshToken();
        
        if (refreshToken) {
            const revocationUrl = environment.authConfig.revocationEndpoint;
            const body = new URLSearchParams();
            body.set('token', refreshToken);
            body.set('client_id', this.oauthService.clientId);
            body.set('token_type_hint', 'refresh_token');

            const headers = new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            });

            return this.http.post(
                revocationUrl,
                body.toString(),
                { headers },
            );

        } else {
            console.error("No valid refresh token.");
            return null;
        }
    }

    checkUserAccess(): Observable<HttpResponse<any>> {
        return this.makeRequest({
            method: BaseService.GET,
            url: "api/tac/v1/user/access"
        });
    }

    getUserInfo(): Observable<HttpResponse<any>> {
        return this.makeRequest({
            method: BaseService.GET,
            url: "api/tac/v1/user/info",
            headerOptions: { observe: "response" }
        });
    }
}